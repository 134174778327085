import styled from "styled-components";

export const ResultsSectionContainer = styled.div`
    & .page-heading__heading {
        text-align: start;
        margin-bottom: 24px !important;
    }

    & .values-section {
        &__image {
            &:hover {
                svg :nth-child(1) {
                    stroke: #c4b180;
                    stroke-opacity: 1;
                }
            }
        }
    }
`;
